import { getSession } from '@auth0/nextjs-auth0'
import { initializeApolloFromContext } from 'api/apollo'
import { BlockContainer, SectionContainer } from 'components/Container'
import AdsGPT from 'components/GPT/AdsGPT'
import FanPass from 'components/Modal/FanPass'
import { Page } from 'components/Page'
import {
    ClipScroller,
    LAZY_CARD_LOADS,
    ReplayScroller,
} from 'components/Scroller/VideoScroller'
import { HomeQL } from 'graphql/club'
import { query } from 'graphql/initialData'
import * as userGQL from 'graphql/user'
import { siteSettings, useRouter } from 'hooks'
import useMediaQuery from 'hooks/useMediaQuery'
import { dfpEvent } from 'lib/tracking/events/fanPass'
import _ from 'lodash'
import type { GetServerSideProps, NextPage } from 'next'
import { createContext, useCallback, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { setClubInfo, setFanPassModalShown } from 'redux/actions/club'
import { setLeagueInfo } from 'redux/actions/league'
import { setPlayerList } from 'redux/actions/players'
import { setSiteSport } from 'redux/actions/site'
import { setUserInfo } from 'redux/actions/user'
import { getClubClips } from 'restful-client/clips'
import { getClubReplays } from 'restful-client/games'
import {
    BannerView,
    GameDayView,
    MediaGalleryView,
    PlayerView,
    SupportView,
    TeamsView,
} from 'sections/club/home'
import { ClubHeadView } from 'sections/club/home/Head'
import { ClubCtx } from 'types/common/club'
import { combineEventsAndMatches, getPageSlugName } from 'utils/common-helper'
import { fetchUserInfo } from 'utils/helper-user-info'

export const ClubContext = createContext<Partial<ClubCtx>>({})

const HomePage: NextPage = (props: any) => {
    const {
        club,
        league,
        has_home_club_fan_pass,
        isFanPassModalShown,
        setFanPassModalShown,
        meta,
        initialData,
        setSiteSport,
        setClubInfo,
        userInfo,
    } = props
    const [banner, setBanner] = useState<any>(club.banner_image)
    const [showSupportModal, setShowSupportModal] = useState<boolean>(false)
    const isMobile = useMediaQuery(768)
    const fallback = `https://via.placeholder.com/900x600.png/000/fff?text=${encodeURI(
        club.name || '',
    )}`
    const { path, asPath } = useRouter()

    useEffect(() => {
        function getURLPath() {
            if (showSupportModal || _.isEmpty(club.pass) || isFanPassModalShown)
                return

            setTimeout(() => {
                club.id !== 141 && setShowSupportModal(!has_home_club_fan_pass)
                setFanPassModalShown(true)
            }, 3000)
        }

        getURLPath()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (_.isUndefined(club.banner_image)) {
        setBanner(fallback)
    }

    const handleToggleSupportModal = (flag: boolean) => {
        dfpEvent({
            type: flag ? 'dfp.supportButton' : 'dfp.modalClose',
            clubName: club.name,
            clubId: club.id,
            page: asPath,
        })
        setShowSupportModal(flag)
    }

    const dispatch = useDispatch()
    useEffect(() => {
        setSiteSport(club?.sport)
        setClubInfo(club)
        userInfo && dispatch(setUserInfo(userInfo))
        league && dispatch(setLeagueInfo(league))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getReplaysFunction = useCallback(
        (offset: number) => getClubReplays(LAZY_CARD_LOADS, offset, club.id),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const getClipsFunction = useCallback(
        (offset: number) => getClubClips(LAZY_CARD_LOADS, offset, club.id),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    return (
        <Page {...meta}>
            <ClubContext.Provider value={club}>
                <main>
                    {siteSettings('club_page.banner') && (
                        <SectionContainer
                            display="block"
                            elementProps={{
                                padding: '0',
                                margin: '0',
                                smPadding: '0',
                                smMargin: '0',
                            }}
                            SectionView={BannerView}
                            sectionProps={{ banner }}
                        />
                    )}

                    <SectionContainer
                        display="block"
                        SectionView={ClubHeadView as any}
                        sectionProps={{
                            handleToggleSupportModal,
                            initialData: { initialData },
                        }}
                    />

                    {siteSettings('club_page.gameday') && (
                        <SectionContainer
                            display="block"
                            SectionView={GameDayView as any}
                            sectionProps={{
                                page: 'Club Page',
                                event: 'View Live Match',
                                initialData: { initialData },
                            }}
                        />
                    )}
                    {siteSettings('club_page.replays') && (
                        <BlockContainer>
                            <ReplayScroller
                                initialData={initialData}
                                getReplaysFunction={getReplaysFunction}
                                type="Replay"
                                scrollerTitle="Replays"
                                page="Club Page"
                                event="View Replay"
                                seeAllLink={`club/${club.slug}/replays`}
                                lazyLoadSize={LAZY_CARD_LOADS}
                            />
                        </BlockContainer>
                    )}

                    {isMobile && (
                        <AdsGPT
                            slot={`mobile-${getPageSlugName(path)}-mrec`}
                            mobileSize={[300, 250]}
                            pos={1}
                        />
                    )}

                    {siteSettings('club_page.clips') &&
                    initialData.clips?.length ? (
                        <BlockContainer>
                            <ClipScroller
                                initialData={initialData}
                                scrollerTitle="Featured Clips"
                                page="Club Page"
                                event="View Clip"
                                getClipsFunction={getClipsFunction}
                                lazyCardLoads={LAZY_CARD_LOADS}
                            />
                        </BlockContainer>
                    ) : null}
                    {siteSettings('club_page.teams') &&
                        club.teams.length > 0 && (
                            <SectionContainer
                                display="block"
                                SectionView={TeamsView}
                                sectionProps={{ initialData: { initialData } }}
                            />
                        )}
                    {siteSettings('club_page.players') && (
                        <SectionContainer
                            display="block"
                            SectionView={PlayerView as any}
                            sectionProps={{ initialData: { initialData } }}
                        />
                    )}
                    {siteSettings('club_page.media_gallery') && (
                        <SectionContainer
                            display="block"
                            elementProps={{ position: 'relative' }}
                            SectionView={MediaGalleryView}
                            sectionProps={{ mode: 'club', clubId: club.id }}
                        />
                    )}
                    {siteSettings('club_page.sponsors') && (
                        <SectionContainer
                            display="block"
                            SectionView={SupportView as any}
                        />
                    )}

                    {!isMobile && (
                        <AdsGPT
                            slot={`desktop-${getPageSlugName(path)}-footer`}
                            mobileSize={[320, 50]}
                            pos={2}
                        />
                    )}
                </main>
            </ClubContext.Provider>
            <FanPass
                homeClubId={club.id}
                homeClubName={club.name}
                homeClubImage={club.logo}
                hasSubHomeClub={has_home_club_fan_pass}
                homePriceId={club.pass?.price_id}
                homePassId={club.pass?.id}
                homePassPrice={club.pass?.price}
                showModal={showSupportModal}
                onClose={handleToggleSupportModal}
            />
        </Page>
    )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    /** get user session */
    const sess: any = getSession(context.req, context.res)
    const user = sess?.user

    const { club_slug, team_slug } = context.query
    let homeFanPassStatus = false
    const apolloClient = initializeApolloFromContext(context)

    const userInfo = user && (await fetchUserInfo(apolloClient, user))

    const { data } = await apolloClient.query({
        query: HomeQL.GET_CLUB,
        variables: {
            club_slug,
        },
    })

    const club = data && data.clubs[0]

    const { data: league } = await apolloClient.query({
        query: HomeQL.GET_LEAGUE,
        variables: {
            where: {
                id: { _eq: club?.teams[0]?.league_id },
            },
        },
    })

    if (!_.isUndefined(user) && club.pass?.id) {
        const homeFanPass = await apolloClient.query({
            query: userGQL.query.GET_USER_PASS,
            variables: {
                where: {
                    user_id: { _eq: user.id },
                    status: { _eq: 'active' },
                    pass_id: { _eq: club.pass.id },
                },
            },
        })

        homeFanPassStatus = !_.isEmpty(homeFanPass.data.user_passes)
    }

    const { data: matches } = await apolloClient.query({
        query: query.GET_INITIAL_SUB_MATCHES,
        variables: {
            eventlimit: 100,
            limit: 100,
            where: {
                stream_info: {
                    status: { _neq: 'completed' },
                    is_historic: { _eq: false },
                    archived: { _neq: true },
                },
                league: { sports_id: { _eq: club?.sport_id } },
                _or: team_slug
                    ? [
                          { away_team: { slug: { _eq: team_slug } } },
                          { home_team: { slug: { _eq: team_slug } } },
                      ]
                    : [
                          { away_club_id: { _eq: club?.id } },
                          { home_team: { club_id: { _eq: club?.id } } },
                      ],
            },
            eventWhere: {
                is_private: { _eq: false },
                stream_info: {
                    status: { _neq: 'completed' },
                    is_historic: { _eq: false },
                    archived: { _neq: true },
                },
                club_id: { _eq: club.id },
            },
            order_by: { start_datetime: 'asc' },
        },
    })

    const replays = await getClubReplays(LAZY_CARD_LOADS, 0, club.id)

    const clips = await getClubClips(LAZY_CARD_LOADS, 0, club.id)

    return {
        props: {
            club: club,
            league: league.leagues[0],
            has_home_club_fan_pass: _.isNull(club.pass?.id)
                ? true
                : !_.isUndefined(user) && homeFanPassStatus,
            meta: {
                title: `${club.name} live streams and replays: See it on Streamer`,
                description: `Your home of ${club.name} live streams, replays, teams, player profiles, highlight clips and more.`,
                canonical: `${process.env.AUTH0_BASE_URL}/club/${club.slug}`,
            },
            initialData: {
                matches: combineEventsAndMatches(
                    matches?.event_streams,
                    matches?.matches,
                ),
                replays: combineEventsAndMatches(
                    replays?.event_streams,
                    replays?.matches,
                ),
                clips: clips?.clip_asset_user_club ?? [],
                club: club,
            },
            userInfo: userInfo ?? null,
        },
    }
}

const mapStateToProps = (state: any) => ({
    isFanPassModalShown: state.club.isFanPassModalShown,
})

const mapDispatchToProps = {
    setClubInfo: setClubInfo,
    setFanPassModalShown: setFanPassModalShown,
    setPlayerList: setPlayerList,
    setSiteSport: setSiteSport,
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
